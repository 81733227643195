import { Box, Grid, useTheme } from "@mui/material";
import { NavLink as RouterLink } from "react-router-dom";
import {
  CardMediaStyle,
  StyledCard,
  CoverImgStyle,
  TitleStyle,
  ImageWrapper,
  TechListItem,
  TechListItemWrapper,
  Description,
  CardContentInfo,
  DrillDownText,
} from "./SpeakerCardStyle";
import Slider from "react-slick";
import { useState } from "react";

// ----------------------------------------------------------------------
function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  const theme = useTheme();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={{
        ...style,
        height: "2rem",
        width: "2rem",
        display: "block",
        color: theme.palette.primary.main,
        fontSize: "3em",
        right: "9px",
        zIndex: 1,
      }}
      onClick={onClick}
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M9 5l7 7-7 7"
      />
    </svg>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  const theme = useTheme();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={{
        ...style,
        height: "2rem",
        width: "2rem",
        display: "block",
        color: theme.palette.primary.main,

        fontSize: "3em",
        left: "9px",
        zIndex: 1,
      }}
      onClick={onClick}
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M15 19l-7-7 7-7"
      />
    </svg>
  );
}

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />,
  autoplay: true,
  className: "slider-cont-custom-wrapper",
};

export default function SpeakerCard({ speaker }) {
  const { title, description, link } = speaker;

  return (
    <Grid item xs={12}>
      <StyledCard>
        <ImageWrapper>
          {speaker.images && (
            <Slider {...settings}>
              {speaker.images.map((image) => {
                return (
                  <CardMediaStyle>
                    <CoverImgStyle component="img" alt="img" src={image} />
                  </CardMediaStyle>
                );
              })}
            </Slider>
          )}
        </ImageWrapper>

        <CardContentInfo>
          <TitleStyle>{title}</TitleStyle>

          <Description>{description}</Description>

          <TechListItemWrapper>
            {speaker.technology.map((tech) => {
              return (
                <Box>
                  <TechListItem label={tech} variant="outlined" />
                </Box>
              );
            })}
          </TechListItemWrapper>
          {/* <DrillDownText component={RouterLink} to={link}>
            Drill down
          </DrillDownText> */}
          <a href={speaker.link} TARGET="_blank" style={{ color: "green" }}>
            Drill down
          </a>
        </CardContentInfo>
      </StyledCard>
    </Grid>
  );
}
