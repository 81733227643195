// ----------------------------------------------------------------------

const NavbarConfig = [
  {
    title: "About",
    path: "/about",
  },
  {
    title: "Portfolio",
    path: "/portfolio",
  },
  {
    title: "Speaker",
    path: "/speaker",
  },
  // {
  //   title: "Writing",
  //   path: "/writing",
  // },
  {
    title: "Hire Me",
    path: "https://calendly.com/sentick/30min",
    external: true,
  },
];

export default NavbarConfig;
